import { Dialog } from '@headlessui/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ReactPlayer from 'react-player'
import PlayIcon from '../../../assets/svgs/play.svg'
import LogoBig from '../../../assets/svgs/playa-pallet-logo-big.svg'
import LogoSmall from '../../../assets/svgs/playa-pallet-logo-small.svg'
import PPVideo from '../../../assets/videos/pp-trimmed.mp4'
import { Trans } from '../../../plugins/gatsby-plugin-react-i18next'
import { Button } from '../buttons'

const Hero: React.FC = () => {
  const [videoPlaying, setVideoPlaying] = React.useState(false)

  const handleStartVideo = () => {
    setVideoPlaying(true)
  }

  const handleStopVideo = () => {
    setVideoPlaying(false)
  }

  return (
    <div className="relative w-full">
      <div className="w-full bg-teal-900">
        <StaticImage
          className="w-full opacity-50 h-128"
          src="../../../assets/images/hero-image.png"
          alt="beach"
        />
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-center p-6 space-y-8 text-white fill-current">
        <LogoSmall className="block md:hidden" width="280" height="149" />
        <LogoBig className="hidden md:block" width="640" height="72" />
        <h3 className="mt-8 text-2xl leading-7 text-center text-white uppercase md:text-3xl">
          <Trans>Beach Cleanups are now 24/7.</Trans>
        </h3>
        <Button
          className="flex px-8 py-4 mt-2 !text-teal-500 !bg-white rounded-full"
          onClick={handleStartVideo}
        >
          <PlayIcon width="24" height="24" className="mr-2 text-teal-500 stroke-current" />
          <span>
            <Trans>Watch the video</Trans>
          </span>
        </Button>
      </div>
      <Dialog
        open={videoPlaying}
        onClose={handleStopVideo}
        className="fixed inset-0 z-50 overflow-y-auto"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-90" />
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <div
            className="relative max-w-xl p-0 mx-auto bg-white rounded md:max-w-full"
            onClick={handleStopVideo}
            role="button"
            tabIndex={0}
          >
            <ReactPlayer
              url={PPVideo}
              width="100%"
              height="100%"
              playing={true}
              onEnded={handleStopVideo}
            />
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default Hero
