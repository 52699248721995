import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

export interface IImageGridData {
  allFile: {
    edges: {
      node: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
  }
}

const query = graphql`
  query ImageGridQuery {
    allFile(
      limit: 12
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "images-grid" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, aspectRatio: 1)
          }
        }
      }
    }
  }
`

const ImageGrid = () => {
  const {
    allFile: { edges: images }
  } = useStaticQuery<IImageGridData>(query)

  return (
    <div className="grid grid-cols-12">
      {images.map(({ node: { childImageSharp: { gatsbyImageData: image } } }, index) => (
        <div className="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2" key={index}>
          <GatsbyImage image={image} alt="photo" />
        </div>
      ))}
    </div>
  )
}

export default ImageGrid
