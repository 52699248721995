import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Trans, useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { dialogType } from '../../../types/app'
import { dispatch } from '../../state'
import { ButtonOutline } from '../buttons'

const Steps: React.FC = () => {
  const { t } = useTranslation('translation')

  const handleDialogOpen = (dialog: dialogType) => {
    console.log('open dialog', dialog)
    dispatch({
      type: 'setDialogState',
      value: dialog
    })
  }

  return (
    <div className="px-6 my-24">
      <h2 className="max-w-2xl mx-auto text-5xl leading-none text-center uppercase text-primary">
        <Trans>Join us!</Trans>
        <br />
        <Trans>It’s super easy.</Trans>
      </h2>
      <div className="grid grid-cols-3 px-6 mx-auto mt-24 mb-16 gap-x-6 gap-y-12">
        <div className="flex flex-col col-span-3 p-6 space-y-4 border border-gray-300 rounded-lg md:col-span-1">
          <div className="flex items-center self-center justify-center w-12 h-12 mb-0 -mt-12 text-3xl leading-none uppercase bg-teal-700 border border-gray-300 rounded-full">
            <span className="font-bold text-white">1</span>
          </div>
          <StaticImage src="../../../assets/images/step-1.jpg" className="w-full" alt="Map" />
          <p className="text-lg font-bold text-center text-primary">
            <Trans>Check the map to see the locations of the Playa Pallets.</Trans>
          </p>
        </div>
        <div className="flex flex-col col-span-3 p-6 space-y-4 border border-gray-300 rounded-lg md:col-span-1">
          <div className="flex items-center self-center justify-center w-12 h-12 mb-0 -mt-12 text-3xl leading-none uppercase bg-teal-700 border border-gray-300 rounded-full">
            <span className="font-bold text-white">2</span>
          </div>
          <StaticImage src="../../../assets/images/step-2.jpg" alt="Pallet" />
          <p className="text-lg font-bold text-center text-primary">
            <Trans>Put the trash you picked up during your walk in the pallet.</Trans>
          </p>
        </div>
        <div className="flex flex-col col-span-3 p-6 space-y-4 border border-gray-300 rounded-lg md:col-span-1">
          <div className="flex items-center self-center justify-center w-12 h-12 mb-0 -mt-12 text-3xl leading-none uppercase bg-teal-700 border border-gray-300 rounded-full">
            <span className="font-bold text-white">3</span>
          </div>
          <StaticImage src="../../../assets/images/step-3.jpg" alt="Pick up" />
          <p className="text-lg font-bold text-center text-primary">
            <Trans>The trash will be picked up regularly by local authorities.</Trans>
          </p>
        </div>
      </div>

      <div className="max-w-2xl mx-auto">
        <p className="mb-12 text-lg font-bold text-center text-primary">
          <Trans>
            If you discover a pallet that is full, broken, or even a missing pallet, please let us
            know so we can fix the issue.
          </Trans>
        </p>
      </div>

      <div className="container flex flex-col justify-center max-w-4xl mx-auto space-y-6 md:flex-row md:space-y-0 md:space-x-6">
        <ButtonOutline className="w-full" onClick={() => handleDialogOpen('dialogFull')}>
          {t('A pallet is full?')}
        </ButtonOutline>

        <ButtonOutline className="w-full" onClick={() => handleDialogOpen('dialogBroken')}>
          {t('A pallet is broken?')}
        </ButtonOutline>

        <ButtonOutline className="w-full" onClick={() => handleDialogOpen('dialogGone')}>
          {t('A pallet is gone?')}
        </ButtonOutline>

        <ButtonOutline className="w-full" onClick={() => handleDialogOpen('dialogOther')}>
          {t('Get in touch.')}
        </ButtonOutline>
      </div>
    </div>
  )
}

export default Steps
