import React from 'react'
import { useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { dialogType } from '../../../types/app'
import { dispatch, useGlobalState } from '../../state'
import FormContact from '../forms/form-contact'
import FormIssue from '../forms/form-issue'
import ModalForm from '../forms/modal-form'

const Dialogs = () => {
  const { t } = useTranslation('translation')
  const [dialogState] = useGlobalState('dialogState')

  const handleDialogClose = (dialog: dialogType) => {
    if (dialogState === dialog) {
      dispatch({
        type: 'setDialogState',
        value: null
      })
    }
  }

  return (
    <>
      {dialogState === 'dialogFull' && (
        <ModalForm
          isOpen={dialogState === 'dialogFull'}
          onClose={() => handleDialogClose('dialogFull')}
        >
          <FormIssue
            formName="pallet-full"
            messageSubject="A pallet is full!"
            headline={t('A pallet is full?')}
            copytext={t(
              'Please tell us the location of the pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalForm>
      )}

      {dialogState === 'dialogBroken' && (
        <ModalForm
          isOpen={dialogState === 'dialogBroken'}
          onClose={() => handleDialogClose('dialogBroken')}
        >
          <FormIssue
            formName="pallet-broken"
            messageSubject="A pallet is broken!"
            headline={t('A pallet is broken?')}
            copytext={t(
              'Please tell us the location of the pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalForm>
      )}

      {dialogState === 'dialogGone' && (
        <ModalForm
          isOpen={dialogState === 'dialogGone'}
          onClose={() => handleDialogClose('dialogGone')}
        >
          <FormIssue
            formName="pallet-gone"
            messageSubject="A pallet is gone!"
            headline={t('A pallet is gone?')}
            copytext={t(
              'Please tell us the location of the missing pallet and leave your eMail in case we need to check back.'
            )}
          />
        </ModalForm>
      )}

      {dialogState === 'dialogOther' && (
        <ModalForm
          isOpen={dialogState === 'dialogOther'}
          onClose={() => handleDialogClose('dialogOther')}
        >
          <FormContact
            formName="other"
            messageSubject="Other topic!"
            headline={t('Get in touch.')}
            copytext={t('You have questions, feedback, or simply want to say hello?')}
          />
        </ModalForm>
      )}
    </>
  )
}

export default Dialogs
