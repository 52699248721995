import Papa from 'papaparse'
import React from 'react'
import { Helmet, useTranslation } from '../../plugins/gatsby-plugin-react-i18next'
import Contact from '../components/contact'
import Dialogs from '../components/dialogs'
import Divider from '../components/divider'
import Footer from '../components/footer'
import Header from '../components/header'
import Hero from '../components/hero'
import ImageGrid from '../components/image-grid'
import Intro from '../components/intro'
import Map from '../components/map'
import Steps from '../components/steps'
import { dispatch } from '../state'

const IndexPage: React.FC = () => {
  const { t } = useTranslation('translation')

  React.useEffect(() => {
    dispatch({ type: 'setHeaderInverted', value: false })
    dispatch({ type: 'setPalletDataLoading', value: true })
    Papa.parse(process.env.GATSBY_SPREADSHEET_URL!, {
      download: true,
      header: true,
      complete: (results: any) => {
        const data = results.data
        console.log(results)
        dispatch({ type: 'setPalletDataLoading', value: false })
        dispatch({ type: 'setPalletData', value: data })
      }
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>{t('Playa Pallet')}</title>
        <meta
          name="description"
          content={`
            ${t(
              `The Playa Pallet is a wooden box on the beach made out of used pallets. There you can drop off any trash you find on the beaches.`
            )}
            ${' '}
            ${t(
              `This way you help to prevent even more trash and plastic entering our oceans and help the marine wildlife. Get a 👍 from nature!`
            )}
          `}
        />
        <meta name="keywords" content="gatsby, react, wapps, i18next" />
      </Helmet>
      <Header />
      <Hero />
      <Intro />
      <ImageGrid />
      <Steps />
      <Map />
      <Contact />
      <Divider />
      <Footer />
      <Dialogs />
    </>
  )
}

export default IndexPage
