import React from 'react'
import { Trans } from '../../../plugins/gatsby-plugin-react-i18next'

const Intro = () => {
  return (
    <div className="max-w-2xl px-6 mx-auto my-24">
      <h2 className="mb-8 text-5xl leading-none text-center uppercase text-primary">
        <Trans>Lend the oceans a helping hand.</Trans>
      </h2>
      <p className="text-lg font-bold text-center text-primary">
        <Trans>
          The Playa Pallet is a wooden box on the beach made out of used pallets. There you can drop
          off any trash you find on the beaches.
        </Trans>
      </p>
      <p className="text-lg font-bold text-center text-primary">
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <Trans>
          This way you help to prevent even more trash and plastic entering our oceans and help the
          marine wildlife. Get a 👍 from nature!
        </Trans>
      </p>

      <div className="p-6 mt-12 space-y-4 rounded-lg bg-teal-50">
        <p className="text-lg font-bold text-center text-primary">
          <Trans>
            If you want to support the Playa Pallet, please consider a donation to the Clean Ocean
            Project.
          </Trans>
        </p>
        <a
          className="block w-full text-center text-teal-500 underline"
          href="http://cleanoceanproject.org/support"
          target="_blank"
          rel="noreferrer"
        >
          <Trans>Donate here</Trans>
        </a>
      </div>
    </div>
  )
}

export default Intro
