import React from 'react'
import { Trans, useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { dialogType } from '../../../types/app'
import { dispatch } from '../../state'
import { ButtonOutline } from '../buttons'

const Contact: React.FC = () => {
  const { t } = useTranslation('translation')

  const handleDialogOpen = (dialog: dialogType) => {
    dispatch({
      type: 'setDialogState',
      value: dialog
    })
  }

  return (
    <div className="flex flex-col items-center max-w-2xl px-6 mx-auto space-y-8">
      <h2 className="text-5xl leading-none text-center uppercase text-primary">
        <Trans>Join the Playa Pallet.</Trans>
      </h2>
      <p className="text-lg font-bold text-center text-primary">
        <Trans>
          You want to support the Playa Pallet Team? We could use your help to set up new pallets,
          maintain existing pallets and expand to new shores. Please get in touch!
        </Trans>
      </p>
      <div className="">
        <ButtonOutline onClick={() => handleDialogOpen('dialogOther')} className="">
          {t('Get in touch.')}
        </ButtonOutline>
      </div>
    </div>
  )
}

export default Contact
