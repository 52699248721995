import React from 'react'
import { Trans, useTranslation } from '../../../plugins/gatsby-plugin-react-i18next'
import { Button } from '../buttons'

const encode = (data: { [key: string]: any }) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

export interface FormProps {
  formName: string
  messageSubject: string
  headline: string
  copytext: string
}

const FormContact: React.FC<FormProps> = ({ formName, messageSubject, headline, copytext }) => {
  const { t } = useTranslation('translation')
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [message, setMessage] = React.useState('')

  const handleSubmit = (e: React.FormEvent) => {
    const formData = {
      messageSubject,
      email,
      subject,
      message
    }

    // console.log('submit', formName, formData);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        ...formData
      })
    })
      .then(() => {
        // console.log('submit went through', response);
        setSuccess(true)
      })
      .catch(() => {
        setError(true)
      })

    e.preventDefault()
  }

  if (success) {
    return (
      <div className="mx-auto">
        <h3 className="mb-2">
          <Trans>Thank you!</Trans>
        </h3>
        <p>
          <Trans>
            We received your message and will take care of it. If you sent us a general inquiry
            someone will get in touch soon.
          </Trans>
        </p>
      </div>
    )
  }

  if (error) {
    return (
      <div className="mx-auto">
        <h3 className="mb-2">
          <Trans>Snap, this didn't work.</Trans>
        </h3>
        <p>
          <Trans>
            The message could not be sent. Should the problem persist, please send your message via
            mail to:
          </Trans>
          <a href={`mailto:${process.env.GATSBY_FALLBACK_MAIL}`}>
            {process.env.GATSBY_FALLBACK_MAIL}
          </a>
        </p>
      </div>
    )
  }

  return (
    <div className="mx-auto">
      <h3 className="mb-4 text-5xl leading-none text-center uppercase">{headline}</h3>
      <p className="mb-8 text-center">{copytext}</p>
      <form onSubmit={handleSubmit} data-netlify="true">
        <input type="hidden" name="form-name" value={formName} />
        <div>
          <input
            className="w-full h-12 px-4 mb-3 border border-gray-400 rounded sm:mb-6"
            type="text"
            name="email"
            id="email"
            placeholder={t('Your eMail address')}
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value)
            }}
          />
        </div>
        <div>
          <input
            className="w-full h-12 px-4 mb-3 border border-gray-400 rounded sm:mb-6"
            type="text"
            name="subject"
            id="subject"
            placeholder={t('Subject')}
            value={subject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSubject(e.target.value)
            }}
          />
        </div>
        <div>
          <textarea
            className="w-full h-24 px-4 py-2 mb-3 border border-gray-400 rounded sm:mb-6"
            name="message"
            id="message"
            placeholder={t('Message')}
            value={message}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setMessage(e.target.value)
            }}
          />
        </div>
        <Button
          className="w-full"
          type="submit"
          disabled={email === '' || subject === '' || message === ''}
        >
          <Trans>Submit message</Trans>
        </Button>
      </form>
    </div>
  )
}

export default FormContact
