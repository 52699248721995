import React from 'react'
import WaveLine from '../../../assets/svgs/wave-line.svg'

const Divider = () => (
  <div className="w-full flex flex-shrink-0 justify-center my-12 mx-auto max-w-2xl overflow-hidden">
    <WaveLine className="flex-shrink-0" width="642" height="7" />
  </div>
)

export default Divider
